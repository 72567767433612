<template>
  <Header title="Financial Planning" />

  <Container>
    <h2>Financial Planning Services</h2>
    <p>
      Financial planning is a conscious and deliberate process followed over an
      extended period of time during which wealth is accumulated, investments
      are made and protected and, eventually, income in retirement is derived
      and wealth is passed on to heirs.
      <br /><br />
      Bryan and Hedden offers a broad range of services related to financial
      planning, wealth management and tax and estate planning. Using a
      confidential computerized analysis, we listen to a work with our
      individual clients to establish a set of financial goals and objectives.
      <br /><br />
      Some of the specific services we provide in this are include:
    </p>
    <ul class="space-y-5 mt-4 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-x-8 lg:gap-y-5">
      <li class="flex items-start lg:col-span-1"><svg class="h-5 w-5 mt-1 text-green-400" x-description="Heroicon name: solid/check-circle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#FFB600" aria-hidden="true">
		  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
		</svg> 
	<p class="ml-3">Personal financial planning</p></li>
      <li class="flex items-start lg:col-span-1"><svg class="h-5 w-5 mt-1 text-green-400" x-description="Heroicon name: solid/check-circle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#FFB600" aria-hidden="true">
		  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
		</svg> 
	<p class="ml-3">Tax and estate planning</p></li>
      <li class="flex items-start lg:col-span-1"><svg class="h-5 w-5 mt-1 text-green-400" x-description="Heroicon name: solid/check-circle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#FFB600" aria-hidden="true">
		  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
		</svg> 
	<p class="ml-3">Investment strategies</p></li>
      <li class="flex items-start lg:col-span-1"><svg class="h-5 w-5 mt-1 text-green-400" x-description="Heroicon name: solid/check-circle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#FFB600" aria-hidden="true">
		  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
		</svg> 
	<p class="ml-3">Insurance planning and consultation</p></li>
      <li class="flex items-start lg:col-span-1"><svg class="h-5 w-5 mt-1 text-green-400" x-description="Heroicon name: solid/check-circle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#FFB600" aria-hidden="true">
		  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
		</svg> 
	<p class="ml-3">Retirement planning</p></li>
      <li class="flex items-start lg:col-span-1"><svg class="h-5 w-5 mt-1 text-green-400" x-description="Heroicon name: solid/check-circle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#FFB600" aria-hidden="true">
		  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
		</svg> 
	<p class="ml-3">Pension analysis and review</p></li>
      <li class="flex items-start lg:col-span-1"><svg class="h-5 w-5 mt-1 text-green-400" x-description="Heroicon name: solid/check-circle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#FFB600" aria-hidden="true">
		  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
		</svg> 
	<p class="ml-3">Tax deferral strategies</p></li>
    </ul>
    <p class="mt-6">
      At Bryan and Hedden, on an independent basis, we offer a diverse range of
      solutions to fulfill the objectives. Implementation of a plan could
      include a combination of financial products and approaches. These may
      include:
    </p>
    <ul class="space-y-5 mt-4 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-x-8 lg:gap-y-5">
      <li class="flex items-start lg:col-span-1"><svg class="h-5 w-5 mt-1 text-green-400" x-description="Heroicon name: solid/check-circle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#FFB600" aria-hidden="true">
		  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
		</svg> 
	<p class="ml-3">Discretionary money management</p></li>
      <li class="flex items-start lg:col-span-1"><svg class="h-5 w-5 mt-1 text-green-400" x-description="Heroicon name: solid/check-circle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#FFB600" aria-hidden="true">
		  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
		</svg> 
	<p class="ml-3">Guaranteed investment funds</p></li>
      <li class="flex items-start lg:col-span-1"><svg class="h-5 w-5 mt-1 text-green-400" x-description="Heroicon name: solid/check-circle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#FFB600" aria-hidden="true">
		  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
		</svg> 
	<p class="ml-3">Leveraged guaranteed investment funds</p></li>
      <li class="flex items-start lg:col-span-1"><svg class="h-5 w-5 mt-1 text-green-400" x-description="Heroicon name: solid/check-circle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#FFB600" aria-hidden="true">
		  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
		</svg> 
	<p class="ml-3">Tax sheltered investment vehicles</p></li>
      <li class="flex items-start lg:col-span-1"><svg class="h-5 w-5 mt-1 text-green-400" x-description="Heroicon name: solid/check-circle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#FFB600" aria-hidden="true">
		  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
		</svg> 
	<p class="ml-3">Yield enhancement strategies</p></li>
      <li class="flex items-start lg:col-span-1"><svg class="h-5 w-5 mt-1 text-green-400" x-description="Heroicon name: solid/check-circle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#FFB600" aria-hidden="true">
		  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
		</svg> 
	<p class="ml-3">Trusts and holding companies</p></li>
      <li class="flex items-start lg:col-span-1"><svg class="h-5 w-5 mt-1 text-green-400" x-description="Heroicon name: solid/check-circle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#FFB600" aria-hidden="true">
		  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path>
		</svg> 
	<p class="ml-3">Personal estate bonds</p></li>
    </ul>
    <p class="mt-6">
      On a consistent and regular bases we review our client's progress in
      achieving their goals and recommend new courses of action if appropriate.
    </p>
  </Container>
</template>

<script>
import Container from "@/components/Container.vue";
import Header from "@/components/Header.vue";

export default {
  components: {
    Container,
    Header,
  },
};
</script>
